<template>
	<div>
		<!-- Toolbar -->
		<n-toolbar :title="currentShop.name" left-text="登出" right-text="訂單" @clickLeft="$router.push('/signIn')" @clickRight="$router.push('/adjustment')" />
		<!-- content -->
		<div class="bg-light-blue-gray h-screen overflow-auto">
			<!-- Header -->
			<div class="sticky top-0 z-10 w-full bg-light-blue-gray p-2 flex justify-center text-xl font-bold text-secondary">商品類別</div>
			<!-- category list -->
			<div class="flex flex-wrap px-3 pb-20">
				<div v-for="categoryId in catalog('MENU')[0].menu.categoryIds" :key="categoryId" class="w-1/3 h-24 text-md p-1">
					<n-button class="active:bg-primary active:text-white" color="light" @click="$router.push(`/category/${categoryId}`)">{{ catalogItem(categoryId).category.name }}</n-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	computed: {
		...mapGetters(['currentShop', 'catalog', 'catalogItem']),
	},
}
</script>
